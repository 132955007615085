export default {
  en: {
    'header-locale-text': 'Change Language',
    'password-requirements-header': 'Password Requirements',
    'password-requirements-text1': 'Password must be longer than 8 characters',
    'password-requirements-text2': 'Must contain one capital letter',
    'password-requirements-text3': 'Must contain one number',
    'password-requirements-text4': 'Must contain one symbol (ex: ! @ # $ *)', 
    'username-requirements-header': 'Username Requirements',
    'username-requirements-text1': 'The username must be unique',
    'username-requirements-text2': 'A combination of letters and numbers is recommended',
    'username-requirements-text3': 'Username must be 8 characters or more',
    'username-requirements-text4': 'Username must be 30 characters or fewer',
  },
  fr: {
    'header-locale-text': 'Changer de langue',
    'password-requirements-header': 'Exigences relatives au mot de passe',
    'password-requirements-text1': 'Le mot de passe doit comporter plus de 8 caractères',
    'password-requirements-text2': 'Doit contenir une lettre majuscule',
    'password-requirements-text3': 'Doit contenir un chiffre',
    'password-requirements-text4': 'Doit contenir un symbole (ex:! @ # $ *)',
    'username-requirements-header': 'Exigences relatives au nom d\'utilisateur',
    'username-requirements-text1': 'Le nom d\'utilisateur doit être unique',
    'username-requirements-text2': 'Une combinaison de lettres et de chiffres est recommandée',
    'username-requirements-text3': 'Le nom d\'utilisateur doit comporter plus de 8 caractères',
    'username-requirements-text4': 'Le nom d\'utilisateur doit comporter moins de 30 caractères',

  },
};
